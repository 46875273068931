<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>关键字回复</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="关键字">
                                <el-input v-model="search.keyword"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                                   @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                                   @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button v-if="is_auth('wxmp.keyword.issave')" icon="el-icon-plus"
                                   size="medium" type="primary" @click="add_page">添加
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    size="medium"
                    border
                    v-loading="loading"
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        prop="appid"
                        label="appid">
                </el-table-column>
                <el-table-column
                        prop="keyword"
                        label="关键字">
                </el-table-column>
                <el-table-column
                        prop="type"
                        label="类型">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.type===1" type="info">文字</el-tag>
                        <el-tag size="mini" v-if="scope.row.type===2">图文</el-tag>
                        <el-tag size="mini" v-if="scope.row.type===3" type="success">图片</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="content"
                        label="回复内容">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type===1">{{ scope.row.content }}</span>
                        <div v-if="scope.row.type===2">
                            <el-tag type="info" size="mini" v-for="(item,index ) in scope.row.wxcontent_info"
                                    :key="index">
                                {{ item.title }}
                            </el-tag>
                        </div>
                        <el-image
                                v-if="scope.row.type===3"
                                style="width: 22px; height: 22px; margin-right: 4px;"
                                :src="scope.row.url_qn"
                                :preview-src-list="[scope.row.url_qn]"
                                fit="contain">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="state"
                        label="状态">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.state===1">正常</el-tag>
                        <el-tag size="mini" v-if="scope.row.state===2" type="danger">禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="update_time"
                        label="修改时间">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        width="150">
                    <template slot-scope="scope">
                        <el-button
                                v-if="is_auth('wxmp.keyword.issave')"
                                @click="edit_page(scope.row)"
                                size="mini">编辑
                        </el-button>
                        <el-button
                                v-if="is_auth('wxmp.keyword.isdel')"
                                @click="isdel(scope.row.id)"
                                type="danger"
                                size="mini">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>

        </div>

        <!--编辑-->
        <el-dialog v-loading="loading"
                   v-if="is_auth('wxmp.keyword.issave')"
                   title="编辑关键字"
                   :visible.sync="editPage"
                   width="50%"
                   :close-on-press-escape="false"
                   :close-on-click-modal="false"
        >
            <el-row>
                <el-form size="medium" ref="form" :label-width="this.env.label_width">
                    <el-col :span="24">
                        <el-form-item label="关键字">
                            <el-input v-model="info.keyword"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="类型">
                            <el-radio-group v-model="info.type">
                                <el-radio :label="1">文本</el-radio>
                                <el-radio :label="2">图文</el-radio>
                                <el-radio :label="3">图片</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item v-show="info.type===1" label="回复内容">
                            <el-input v-model="info.content" type="textarea" maxlength="200" show-word-limit
                                      rows="3"></el-input>
                        </el-form-item>
                        <el-form-item v-show="info.type===2" label="选择图文">
                            <el-select
                                    v-model="info.wxcontent_s"
                                    multiple
                                    filterable
                                    placeholder="请选择图文"
                                    remote
                                    :remote-method="getwxcontent"
                                    style="width: 100%">
                                <el-option
                                        v-for="(item,index) in wxcontent_list"
                                        :key="index"
                                        :label="item.title"
                                        :value="item.wxcontent_uuid">
                                </el-option>
                            </el-select>
                            <span style="display: block; color: #fc142f; font-size: 12px; line-height: 20px;">当用户发送文本、图片、语音、视频、图文、地理位置这六种消息时，只能回复1条图文消息；其余场景最多可回复8条图文消息</span>
                        </el-form-item>
                        <el-form-item v-show="info.type===3" label="素材id">
                            <el-input v-model="info.media_id"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="状态">
                            <el-radio-group v-model="info.state">
                                <el-radio :label="1">启用</el-radio>
                                <el-radio :label="2">禁用</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>
            <el-row style="margin: 10px 0px;">
                <el-button size="medium" type="primary" @click="issave">确 定</el-button>
                <el-button size="medium" @click="editPage = false">取 消</el-button>
            </el-row>

        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '关键字回复',
            loading: true,      // 加载状态
            issearch: false,    // 搜索是否展示
            search: {},         // 搜索内容
            tableData: [],      // 列表内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            editPage: false,
            info: {
                type: 1,
                state: 1,
                wxcontent_s: []
            },           // 详情
            wxcontent_list: [], // 可用图文
        }
    },
    // 创建
    created() {
        this.init()
        this.getwxcontent(null);        // 获取可用图文
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                keyword: '',
            }
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "wxmp.keyword.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            console.log('postdata', postdata)

            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 获取图文
        getwxcontent(title) {
            if (title === '') return false;
            let postdata = {
                api_name: "wxmp.wxcontent.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: 20,
                title,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.wxcontent_list = json.data.list
                } else {
                    this.wxcontent_list = []
                }
            })

        },

        // 添加页面
        add_page() {
            this.info = {type: 1, state: 1, wxcontent_s: []}
            this.editPage = true    // 显示页面
        },
        // 编辑显示
        edit_page(row = {}) {
            this.info = row
            this.editPage = true    // 显示页面
        },
        // 保存
        issave() {
            let postdata = {
                api_name: 'wxmp.keyword.issave',
                token: this.Tool.get_l_cache('token'),
            }
            Object.assign(postdata, this.info);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.editPage = false
                    this.$message({
                        message: '保存成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()
                        }
                    });

                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 删除
        isdel(id) {
            let postdata = {
                api_name: 'wxmp.keyword.isdel',
                token: this.Tool.get_l_cache('token'),
                id,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.editPage = false
                    this.$message({
                        message: '删除成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()
                        }
                    });

                } else {
                    this.$message.error(json.message);
                }
            })
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*表格不换行*/
>>> .el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}

</style>
